import ApiBase from '@/api/base'

export default {
	state: {
		cryptoPaymentsList: []
	},
	getters: {
		cryptoPaymentsList: state => {
			return state.cryptoPaymentsList.map(items => {
				items.imageUrl = ApiBase.baseApiUrl() + '/' + items.imageUrl
				return items
			})
		}
	},
	mutations: {
		SET_CRYPTO_PAYMENTS_LIST(state, list) {
			state.cryptoPaymentsList = list
		}
	},
	actions: {
		setCryptoPaymentsList(context, list) {
			context.commit('SET_CRYPTO_PAYMENTS_LIST', list)
		}
	}
}
