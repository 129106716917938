import Vue from "vue";
import VueRouter from "vue-router";

import Home from "./routes/home";
import Help from "./routes/help";
import CustomPage from "./routes/custom-page";
import WhiteLabel from "./routes/white-label";
import CustodySolution from "./routes/custody-solution";
import PremiumExchange from "./routes/premium-exchange";
import BecomePartner from "./routes/become-partner";
import BrandGuide from "./routes/brand-guide";
import AboutUs from "./routes/about-us";
import Wallet from "./routes/wallet";
import Careers from "./routes/careers";
import AmlPolicy from "./routes/aml-policy";
import PrivacyPolicy from "./routes/privacy-policy";
import Complaints from "./routes/complaints";
import TermsOfService from "./routes/terms-of-service";
import RiskDisclaimer from "./routes/risk-disclaimer";
import CurrentPosition from "./routes/current-position";
import WelcomePage from "./routes/welcome-page";
import GetAccessPage from "./routes/get-access-page";
import UnderConstruction from "./routes/under-construction";
import BlogPage from "./routes/blog-page";
import PostPage from "./routes/post-page";
import Page404 from "./routes/404-page";
import ComingSoon from "./routes/coming-soon";
import Redirect from "./routes/alias-redirect";

Vue.use(VueRouter);

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/",
			component: () =>
				import("@/components/coming-soon-container/ComingSoonContainer"),
			children: [ComingSoon, BlogPage, PostPage, Page404],
		},
		{
			path: "/show-app",
			component: () => import("@/components/main-container/MainContainer"),
			children: [
				Home,
				Help,
				CustomPage,
				WhiteLabel,
				CustodySolution,
				PremiumExchange,
				BecomePartner,
				BrandGuide,
				AboutUs,
				Wallet,
				Careers,
				AmlPolicy,
				PrivacyPolicy,
				Complaints,
				TermsOfService,
				RiskDisclaimer,
				CurrentPosition,
				WelcomePage,
				// BlogPage,
				// PostPage,
				GetAccessPage,
				UnderConstruction,
				// Page404,
				// Redirect,
			],
		},
		{
			path: "/",
			component: () =>
				import("@/components/coming-soon-container/ComingSoonContainer"),
			children: [Redirect],
		},
	],
});

router.beforeEach((to, from, next) => {
	// if (to.path !== "/") {
	// 	next("/");
	// } else {
	// 	next();
	// }
	window.scrollTo(0, 0);

	if (!to.meta.middleware) {
		return next();
	}

	const middleware = to.meta.middleware;

	if (middleware.length === 0) {
		return next();
	}
	const context = {
		to,
		from,
		next,
		// store
	};
	for (var key in middleware) {
		middleware[key]({
			...context,
		});
	}
});

export default router;
