import RouterComponent from '@/components/router/Router'

export default {
	path: '/',
	component: RouterComponent,
	children: [
		{
			path: '/*',
			redirect: '/404'
			// redirect: '/'
		}
	]
}