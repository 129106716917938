import RouterComponent from "@/components/router/Router";

export default {
	path: "/",
	component: RouterComponent,
	children: [
		{
			path: "/show-app/premium-exchange",
			name: "PremiumExchange",
			component: () => import("@/pages/premium-exchange/PremiumExchange.vue"),
		},
	],
};
