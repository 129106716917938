export default {
  black: '#2D3436',
  mainColor: '#00CEC9',
  gray: '#B2BEC3',
  grayText: '#636E72',
  borderGray: '#DFE6E9',
  mint: '#F4FCFF',
  violet: '#6C5CE7',
  lightGray: '#F5F5F5',
  salmon: '#FF7675',
  red: '#D63031',
  pink: '#FD79A8',
  white: '#FFFFFF'
}