import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from 'vue-notification'
import VueMeta from 'vue-meta'
import moment from 'vue-moment'

import ApiSettings from '@/api/settings'
import ApiTranslations from '@/api/translations'
import AppLang from '@/api/app-langs'

import 'vuetify/dist/vuetify.min.css'
import '@/assets/scss/app.scss'
import '@/assets/css/icons.css'
import '@/assets/css/animation.css'

import './plugins/axios'
import vuetify from './plugins/vuetify'

require('@/plugins/vueMixin')

Vue.config.productionTip = false

Vue.use(Notifications)
Vue.use(VueMeta)
Vue.use(moment)

new Vue({
	router,
	store,
	vuetify,
	axios,
	VueAxios,
	render: h => h(App),
	mounted() {
		ApiSettings.getSettings(this)
		ApiTranslations.getByCurrentLanguage(this)
		AppLang.getAll(this)
	}
}).$mount('#app')
