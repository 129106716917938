import RouterComponent from "@/components/router/Router";

export default {
	path: "/",
	component: RouterComponent,
	children: [
		{
			path: "/show-app/legal/:slug",
			name: "Custom page",
			component: () => import("@/pages/custom-page/CustomPage.vue"),
		},
	],
};
