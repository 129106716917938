export default {
	state: {
		faqList: []
	},
	getters: {
		faqList: state => {
			return state.faqList.map(items => {
				return items
			})
		}
	},
	mutations: {
		SET_FAQ_LIST(state, list) {
			state.faqList = list
		}
	},
	actions: {
		setFaqList(context, list) {
			context.commit('SET_FAQ_LIST', list)
		}
	}
}
