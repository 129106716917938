import ApiBase from '@/api/base'

export default {
	state: {
		partners: []
	},
	getters: {
		partners: state => {
			return state.partners.map(partner => {
				partner.imageUrl = ApiBase.baseApiUrl() + '/' + partner.image
				return partner
			})
		}
	},
	mutations: {
		SET_PARTNERS (state, list) {
			state.partners = list
		}
	},
	actions: {
		setPartners (context, list) {
			context.commit('SET_PARTNERS', list)
		}
	}
}
