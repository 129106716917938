import RouterComponent from "@/components/router/Router";

export default {
	path: "/",
	component: RouterComponent,
	children: [
		{
			path: "/show-app/disclaimer",
			name: "RiskDisclaimer",
			component: () => import("@/pages/risk-disclaimer/RiskDisclaimer.vue"),
		},
	],
};
