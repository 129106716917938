<template>
<svg height="16" width="16">
  <circle
    cx="8"
    cy="8"
    r="8"
    class="circle"
  />
	<circle
    cx="8"
    cy="8"
    r="4"
    class="circle-filled"
  />
</svg>
</template>

<script>
export default {
  name: 'CheckboxOn'
}
</script>

<style scoped>
.circle {
	stroke: var(--v-mainColor-base);
	fill: transparent;
}
.circle-filled {
	fill: var(--v-mainColor-base);
}
</style>
