import ApiBase from '@/api/base'

class AppLang {
	static getAll (self) {
		self.http('get', `${ApiBase.baseApiUrl()}/app-langs/`)
			.then(response => {
				ApiBase.setToStore(null, 'setAppLangs', response.data)
			})
	}
}

export default AppLang
