<template>
<svg height="16" width="16">
  <circle
    cx="8"
    cy="8"
    r="8"
    class="circle"
  />
</svg> 
</template>

<script>
export default {
  name: 'Delimiter'
}
</script>

<style scoped>
.circle {
    fill: var(--v-lightGray-base);
  }
</style>