export default {
	state: {
		customShortPages: [],
		customOnePage: {}
	},
	getters: {
		customShortPages: state => state.customShortPages,
		customOnePage: state => state.customOnePage
	},
	mutations: {
		SET_CUSTOM_SHORT_PAGES(state, list) {
			state.customShortPages = list
		},
		SET_CUSTOM_ONE_PAGE (state, data) {
			state.customOnePage = data
		}
	},
	actions: {
		setCustomShortPages(context, list) {
			context.commit('SET_CUSTOM_SHORT_PAGES', list)
		},
		setCustomOnePage (context, data) {
			context.commit('SET_CUSTOM_ONE_PAGE', data)
		}
	}
}
