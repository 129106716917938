import ApiBase from '@/api/base'

export default {
	state: {
		team: []
	},
	getters: {
		team: state => {
			return state.team.map(member => {
				member.imageUrl = ApiBase.baseApiUrl() + '/' + member.image
				return member
			})
		}
	},
	mutations: {
		SET_TEAM_LIST (state, list) {
			state.team = list
		}
	},
	actions: {
		setTeamList (context, list) {
			context.commit('SET_TEAM_LIST', list)
		}
	}
}
