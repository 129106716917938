<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22.828" height="42.828" viewBox="0 0 22.828 42.828">
    <g transform="translate(1.414 1.414)">
      <line
        class="a"
        x2="20"
        y2="20" />
      <line
        class="a"
        y1="20"
        x2="20"
        transform="translate(0 20)" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'NextIcon'
}
</script>

<style scoped>
.a {
    fill: none;
    stroke: #b2bec3;
    stroke-linecap: round;
    stroke-width: 2px;
  }
</style>
