export default {
	state: {
		emails: []
	},
	getters: {
		emails: state => state.emails
	},
	mutations: {
		SET_EMAILS (state, list) {
			state.emails = list
		}
	},
	actions: {
		setEmails (context, list) {
			context.commit('SET_EMAILS', list)
		}
	}
}
