import RouterComponent from "@/components/router/Router";

export default {
	path: "/",
	component: RouterComponent,
	children: [
		{
			path: "/show-app/aml-policy",
			name: "AmlPolicy",
			component: () => import("@/pages/aml-policy/AmlPolicy.vue"),
		},
	],
};
