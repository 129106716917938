import ApiBase from '@/api/base'

export default {
	state: {
		providersCategoriesList: []
	},
	getters: {
		providersCategoriesList: state => {
			return state.providersCategoriesList.map(items => {
				items.imageUrl = ApiBase.baseApiUrl() + '/' + items.imageUrl
				return items
			})
		}
	},
	mutations: {
		SET_PROVIDERS_CATEGORIES_LIST(state, list) {
			state.providersCategoriesList = list
		}
	},
	actions: {
		setProvidersCategoriesList(context, list) {
			context.commit('SET_PROVIDERS_CATEGORIES_LIST', list)
		}
	}
}
