import ApiBase from '@/api/base'

export default {
	state: {
		employeeComments: []
	},
	getters: {
		employeeComments: state => {
			return state.employeeComments.map(comment => {
				comment.imageUrl = ApiBase.baseApiUrl() + '/' + comment.image
				return comment
			})
		}
	},
	mutations: {
		SET_EMPLOYEE_COMMENTS (state, list) {
			state.employeeComments = list
		}
	},
	actions: {
		setEmployeeComments (context, list) {
			context.commit('SET_EMPLOYEE_COMMENTS', list)
		}
	}
}
