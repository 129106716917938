export default {
	state: {
		translationsAll: null
	},
	getters: {
		translationsAll: state => state.translationsAll
	},
	mutations: {
		SET_TRANSLATIONS_ALL (state, translations) {
			state.translationsAll = translations
		}
	},
	actions: {
		setTranslationsAll (context, translations) {
			context.commit('SET_TRANSLATIONS_ALL', translations)
		}
	}
}
