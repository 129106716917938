import RouterComponent from "@/components/router/Router";

export default {
	path: "/",
	component: RouterComponent,
	children: [
		{
			path: "/show-app/brand-guide",
			name: "BrandGuide",
			component: () => import("@/pages/brand-guide/BrandGuide.vue"),
		},
	],
};
