'use strict'

import store from '@/store'
import router from '@/router'

class ApiBase {
	static baseApiUrl () {
		return process.env.VUE_APP_API_URL
	}
	static baseUrl () {
		return process.env.VUE_APP_BASE_URL || 'https://1tn.com'
	}

	static defaultLang () {
		return `${process.env.VUE_APP_LANGUAGE}`
	}

	static authHeaders() {
		return {}
	}

	static setToStore (self, name, data, callBack) {
		store.dispatch(name, data)
		if (callBack) {
			callBack()
		}
	}

	static unauthoriseUser (redirectTo) {
		if (redirectTo) {
			router.push(redirectTo)
		}
	}
}

export default ApiBase
