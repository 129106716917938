import RouterComponent from '@/components/router/Router'

export default {
	path: '/',
	component: RouterComponent,
	children: [
		{
			path: '/404',
			name: 'Page404',
			component: () => import('@/pages/404-page/Page404.vue')
		}
	]
}