import ApiBase from '@/api/base'

export default {
	state: {
		providersList: []
	},
	getters: {
		providersList: state => {
			return state.providersList.map(items => {
				items.imageUrl = ApiBase.baseApiUrl() + '/' + items.imageUrl
				return items
			})
		}
	},
	mutations: {
		SET_PROVIDERS_LIST(state, list) {
			state.providersList = list
		}
	},
	actions: {
		setProvidersList(context, list) {
			context.commit('SET_PROVIDERS_LIST', list)
		}
	}
}
