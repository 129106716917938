import ApiBase from '@/api/base'

export default {
	state: {
		chooseUsItemsList: []
	},
	getters: {
		chooseUsItemsList: state => {
			return state.chooseUsItemsList.map(items => {
				items.imageUrl = ApiBase.baseApiUrl() + '/' + items.imageUrl
				return items
			})
		}
	},
	mutations: {
		SET_CHOOSE_US_ITEMS_LIST(state, list) {
			state.chooseUsItemsList = list
		}
	},
	actions: {
		setChooseUsItemsList(context, list) {
			context.commit('SET_CHOOSE_US_ITEMS_LIST', list)
		}
	}
}
