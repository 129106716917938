<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22.828" height="42.828" viewBox="0 0 22.828 42.828">
   
    <g transform="translate(-46.086 -7249.086)">
      <line
        class="a"
        x1="20"
        y2="20"
        transform="translate(47.5 7250.5)" />
      <line
        class="a"
        x1="20"
        y1="20"
        transform="translate(47.5 7270.5)" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PrevIcon'
}
</script>

<style scoped>
.a {
          fill: none;
          stroke: #b2bec3;
          stroke-linecap: round;
          stroke-width: 2px;
        }
</style>
