import ApiBase from '@/api/base'

export default {
	state: {
		supportedCoinsList: []
	},
	getters: {
		supportedCoinsList: state => {
			return state.supportedCoinsList.map(items => {
				items.imageUrl = ApiBase.baseApiUrl() + '/' + items.imageUrl
				return items
			})
		}
	},
	mutations: {
		SET_SUPPORTED_COINS_LIST(state, list) {
			state.supportedCoinsList = list
		}
	},
	actions: {
		setSupportedCoinsList(context, list) {
			context.commit('SET_SUPPORTED_COINS_LIST', list)
		}
	}
}
