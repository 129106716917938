import RouterComponent from "@/components/router/Router";

export default {
	path: "/",
	component: RouterComponent,
	children: [
		{
			path: "/show-app/welcome",
			name: "WelcomePage",
			component: () => import("@/pages/welcome-page/WelcomePage.vue"),
		},
	],
};
