import ApiBase from '@/api/base'

export default {
	state: {
		socialMediasList: []
	},
	getters: {
		socialMediasList: state => {
			return state.socialMediasList.map(social => {
				social.fullImageUrl = ApiBase.baseApiUrl() + '/' + social.imageUrl
				return social
			})
		}
	},
	mutations: {
		SET_SOCIAL_MEDIAS_LIST(state, list) {
			state.socialMediasList = list
		}
	},
	actions: {
		setSocialMediasList(context, list) {
			context.commit('SET_SOCIAL_MEDIAS_LIST', list)
		}
	}
}
