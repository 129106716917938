import colors from '@/assets/configs/colors'

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css'

//icons
import PrevIcon from '@/components/icons/PrevIcon'
import NextIcon from '@/components/icons/NextIcon'
import Delimiter from '@/components/icons/Delimiter'
import UpLoadIcon from '@/components/icons/UpLoadIcon'
import CheckboxOff from '@/components/icons/CheckboxOff'
import CheckboxOn from '@/components/icons/CheckboxOn'


Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		light: true,
		options: {
			customProperties: true
		},
		themes: {
			light: colors
		}
	},
	icons: {
		values: {
			previcon: {
				component: PrevIcon,
				props: 'previcon'
			},
			nexticon: {
				component: NextIcon,
				props: 'nexticon'
			},
			delimiter: {
				component: Delimiter,
				props: 'delimiter'
			},
			uploadicon: {
				component: UpLoadIcon,
				props: 'uploadicon'
			},
			'checkbox-of': {
				component: CheckboxOff,
				props: 'checkbox-of'
			},
			'checkbox-on': {
				component: CheckboxOn,
				props: 'checkbox-on'
			}
		}
	}
});
