import ApiBase from '@/api/base'

export default {
	state: {
		effortlessIntegrationList: []
	},
	getters: {
		effortlessIntegrationList: state => {
			return state.effortlessIntegrationList.map(items => {
				items.imageUrl = ApiBase.baseApiUrl() + '/' + items.imageUrl
				return items
			})
		}
	},
	mutations: {
		SET_EFFORTLESS_INTEGRATION_ITEMS(state, list) {
			state.effortlessIntegrationList = list
		}
	},
	actions: {
		setEffortlessIntegrationItems(context, list) {
			context.commit('SET_EFFORTLESS_INTEGRATION_ITEMS', list)
		}
	}
}
