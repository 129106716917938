import Vue from 'vue'
import Vuex from 'vuex'

import baseStore from './modules/baseStore'
import translationsAll from './modules/translationsAll'
import translations from './modules/translations'
import homeMedalStore from './modules/home-medal-store'
import homeSponsorStore from './modules/home-sponsor-store'
import bestItemsStore from './modules/worlds-best-items-store'
import homeCryptoPayments from './modules/home-crypto-payments'
import homeChooseUsItems from './modules/home-choose-us-items'
import providersCategories from './modules/home-comparing-providers-categories'
import providersList from './modules/home-comparing-providers'
import supportedCoins from './modules/supported-coins'
import effortlessIntegrationStore from './modules/effortless-integration-items'
import testimonialsStore from './modules/testimonials'
import socialMediasListStore from './modules/social-medias'
import globalSettings from './modules/global-settings'
import faqList from './modules/faq'
import openPositions from './modules/open-positions'
import customPageStore from './modules/custom-page-store'
import appLangsStore from './modules/app-langs-store'
import blogStore from './modules/blog-store'
import teamStore from './modules/team-store'
import partnersStore from './modules/partners-store'
import employeeComments from './modules/employee-comments'
import emails from './modules/emails'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		baseStore,
		translationsAll,
		translations,
		homeMedalStore,
		homeSponsorStore,
		bestItemsStore,
		homeCryptoPayments,
		homeChooseUsItems,
		providersCategories,
		providersList,
		supportedCoins,
		effortlessIntegrationStore,
		testimonialsStore,
		socialMediasListStore,
		globalSettings,
		faqList,
		openPositions,
		customPageStore,
		appLangsStore,
		blogStore,
		teamStore,
		partnersStore,
		employeeComments,
		emails
	}
})
