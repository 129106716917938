import RouterComponent from "@/components/router/Router";

export default {
	path: "/",
	component: RouterComponent,
	children: [
		{
			path: "/show-app/complaints",
			name: "Complaints",
			component: () => import("@/pages/complaints/Complaints.vue"),
		},
	],
};
