import ApiBase from '@/api/base'

class ApiSettings {
	static getSettings (self) {
		self.http('get', `${ApiBase.baseApiUrl()}/settings`)
			.then(response => {
				ApiBase.setToStore(null, 'setGlobalSettings', response.data)
			})
	}
}

export default ApiSettings
