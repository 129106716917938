import RouterComponent from '@/components/router/Router'

export default {
	path: '/',
	component: RouterComponent,
	children: [
		{
			path: '/',
			name: 'ComingSoon',
			component: () => import('@/pages/coming-soon/ComingSoon.vue')
		}
	]
}
