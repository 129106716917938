import RouterComponent from "@/components/router/Router";

export default {
	path: "/",
	component: RouterComponent,
	children: [
		{
			path: "/show-app/under-construction",
			name: "UnderConstruction",
			component: () =>
				import("@/pages/under-construction/UnderConstruction.vue"),
		},
	],
};
