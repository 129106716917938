import RouterComponent from "@/components/router/Router";

export default {
	path: "/",
	component: RouterComponent,
	children: [
		{
			path: "/show-app/privacy-policy",
			name: "PrivacyPolicy",
			component: () => import("@/pages/privacy-policy/PrivacyPolicy.vue"),
		},
	],
};
