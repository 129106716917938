export default {
	state: {
		appLangs: []
	},
	getters: {
		appLangs: state => state.appLangs
	},
	mutations: {
		SET_APP_LANGS (state, list) {
			state.appLangs = list.map(item => {
				item.code = item.code.toLowerCase()
				return item
			})
		}
	},
	actions: {
		setAppLangs(context, list) {
			context.commit('SET_APP_LANGS', list)
		}
	}
}
