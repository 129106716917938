import ApiBase from '@/api/base'
import {notify} from '@/helpers/notifyHelper'
import StorageHelper from '@/helpers/localStorageHelper'

class Translations 	{
	static getAllByCategory(self, currentCategory) {
		const category = {category: currentCategory}

		self.http('get', `${ApiBase.baseApiUrl()}/translations/category`, category, ApiBase.authHeaders())
			.then(response => {
				ApiBase.setToStore(null, 'setTranslationsAll', response.data)
			})
	}
	static setOne(self) {
		const form = self.form
		const category = self.form.category

		self.http('post', `${ApiBase.baseApiUrl()}/translations/create`, form, ApiBase.authHeaders())
			.then(response => {
				notify(self, 'success', response.data.message)
				this.getAllByCategory(self, category)
			})
	}
	static async getByCurrentLanguage(self) {
		try {
			const lang = StorageHelper.get('language') || ApiBase.defaultLang()
      let response = await self.http('get', `${ApiBase.baseApiUrl()}/translations/${lang}`)
			ApiBase.setToStore(null, 'setCurrentLang', lang)
			ApiBase.setToStore(null, 'setTranslationsList', response.data)
			ApiBase.setToStore(null, 'setLoadingStatus', false)
    } catch (error) {
			ApiBase.setToStore(null, 'setLoadingStatus', false)
			ApiBase.setToStore(null, 'setServerErrorStatus', true)
      self.notify('error', error.response.data.message)
      console.error('error', error)
    }
	}
	static updateOne(self, key) {
		const form = self.form
		const category = self.currentCategory

		self.http('put', `${ApiBase.baseApiUrl()}/translations/${key}`, form, ApiBase.authHeaders())
			.then(response => {
				this.getAllByCategory(self, category)
				notify(self, 'success', response.data.message)
			})
	}
	static deleteOne(self, key) {
		const category = self.currentCategory
		self.http('delete', `${ApiBase.baseApiUrl()}/translations/delete/${key}`, null, ApiBase.authHeaders())
			.then(response => {
				this.getAllByCategory(self, category)
				notify(self, 'success', response.data.message)
			})
	}
}

export default Translations
