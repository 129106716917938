import ApiBase from '@/api/base'

export default {
	state: {
		homeSponsorList: []
	},
	getters: {
		homeSponsorList: state => {
			return state.homeSponsorList.map(sponsor => {
				sponsor.imageUrl = ApiBase.baseApiUrl() + '/' + sponsor.imageUrl
				return sponsor
			})
		}
	},
	mutations: {
		SET_HOME_SPONSOR_LIST(state, list) {
			state.homeSponsorList = list
		}
	},
	actions: {
		setHomeSponsorList(context, list) {
			context.commit('SET_HOME_SPONSOR_LIST', list)
		}
	}
}
