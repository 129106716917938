import RouterComponent from "@/components/router/Router";

export default {
	path: "/",
	component: RouterComponent,
	children: [
		{
			path: "/show-app/wallet",
			name: "Wallet",
			component: () => import("@/pages/wallet-page/WalletPage.vue"),
		},
	],
};
