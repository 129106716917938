// import ApiBase from '@/api/base'

export default {
	state: {
		bestItemsList: []
	},
	getters: {
		bestItemsList: state => {
			return state.bestItemsList.map(items => {
				// sponsor.imageUrl = ApiBase.baseApiUrl() + '/' + sponsor.imageUrl
				return items
			})
		}
	},
	mutations: {
		SET_BEST_ITEMS_LIST(state, list) {
			state.bestItemsList = list
		}
	},
	actions: {
		setBestItemsList(context, list) {
			context.commit('SET_BEST_ITEMS_LIST', list)
		}
	}
}
