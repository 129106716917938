import RouterComponent from "@/components/router/Router";

export default {
	path: "/",
	component: RouterComponent,
	children: [
		{
			path: "/show-app/get-access",
			name: "GetAccess",
			component: () => import("@/pages/get-access-page/GetAccessPage.vue"),
		},
	],
};
