export default {
  state: {
    translations: {},
    loading: true
  },
  getters: {
    translations: state => state.translations,
    loading: state => state.loading
  },
  mutations: {
    SET_TRANSLATIONS_LIST (state, translations) {
      state.translations = translations
    },
		SET_LOADING_STATUS (state, status) {
			state.loading = status
		}
  },
  actions: {
    setTranslationsList (context, translations) {
      context.commit('SET_TRANSLATIONS_LIST', translations)
    },
		setLoadingStatus (context, status) {
			context.commit('SET_LOADING_STATUS', status)
		}
  }
}
