<template>
<svg id="Group_6607" data-name="Group 6607" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_12495" data-name="Rectangle 12495" width="24" height="24" fill="none" stroke="#6c5ce7" stroke-width="1"/>
    </clipPath>
  </defs>
  <g id="Group_6606" data-name="Group 6606" clip-path="url(#clip-path)">
    <path id="Path_12172" data-name="Path 12172" d="M19,7.5l-7-7-7,7H8.5v11h7V7.5Z" fill="none" stroke="#6c5ce7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    <path id="Path_12173" data-name="Path 12173" d="M23.5,18.5v5H.5v-5" fill="none" stroke="#6c5ce7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
  </g>
</svg>
</template>

<script>
export default {
  name: 'UpLoadIcon'
}
</script>
