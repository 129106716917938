export default {
	state: {
		currentLang: 'en',
		isServerError: false
	},
	getters: {
		currentLang: state => state.currentLang,
		isServerError: state => state.isServerError
	},
	mutations: {
		SET_CURRENT_LANG (state, lang) {
			state.currentLang = lang
		},
		SET_SERVER_ERROR_STATUS (state, status) {
			state.isServerError = status
		}
	},
	actions: {
		setCurrentLang (context, lang) {
			context.commit('SET_CURRENT_LANG', lang)
		},
		setServerErrorStatus (context, lang) {
			context.commit('SET_SERVER_ERROR_STATUS', lang)
		}
	}
}
