export default {
	state: {
		openPositions: [],
		currentPosition: {}
	},
	getters: {
		openPositions: state => state.openPositions,
		currentPosition: state => state.currentPosition
	},
	mutations: {
		SET_OPEN_POSITIONS(state, list) {
			state.openPositions = list
		},
		SET_CURRENT_POSITION(state, data) {
			state.currentPosition = data
		}
	},
	actions: {
		setOpenPositions(context, list) {
			context.commit('SET_OPEN_POSITIONS', list)
		},
		setCurrentPosition(context, data) {
			context.commit('SET_CURRENT_POSITION', data)
		}
	}
}
