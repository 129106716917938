import RouterComponent from "@/components/router/Router";

export default {
	path: "/",
	component: RouterComponent,
	children: [
		{
			path: "/show-app/open-position/:slug",
			name: "CurrentPosition",
			component: () => import("@/pages/current-position/CurrentPosition.vue"),
		},
	],
};
