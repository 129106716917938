export default {
  state: {
    globalSettings: {}
  },
  getters: {
    globalSettings: state => state.globalSettings
  },
  mutations: {
    SET_GLOBAL_SETTINGS (state, data) {
      data.forEach(setting => {
        state.globalSettings[setting.key] = setting.value
      })
    }
  },
  actions: {
    setGlobalSettings (context, data) {
      context.commit('SET_GLOBAL_SETTINGS', data)
    }
  }
}

