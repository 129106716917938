import ApiBase from '@/api/base'

export default {
	state: {
		posts: [],
		post: {}
	},
	getters: {
		posts: state => {
			return state.posts.map(post => {
				post.imageUrl = ApiBase.baseApiUrl() + '/' + post.image
				return post
			})
		},
		post: state => {
			state.post.imageUrl = ApiBase.baseApiUrl() + '/' + state.post.image
			return state.post
		}
	},
	mutations: {
		SET_POSTS (state, data) {
			state.posts = data
		},
		SET_POST (state, data) {
			state.post = data
		}
	},
	actions: {
		setPosts (context, data) {
			context.commit('SET_POSTS', data)
		},
		setPost (context, data) {
			context.commit('SET_POST', data)
		}
	}
}
