export default {
	state: {
		testimonialsList: []
	},
	getters: {
		testimonialsList: state => {
			return state.testimonialsList.map(testimonials => {
				return testimonials
			})
		}
	},
	mutations: {
		SET_TESTIMONIALS_LIST(state, list) {
			state.testimonialsList = list
		}
	},
	actions: {
		setTestimonialsList(context, list) {
			context.commit('SET_TESTIMONIALS_LIST', list)
		}
	}
}
